.app.valid,
.app.valid .details {
    background-color: darkgreen;
    color: white;
}

.app.invalid-json,
.app.invalid-json .details {
    background-color: darkred;
    color: white;
}

.app.invalid-structure,
.app.invalid-structure .details {
    background-color: darkgrey;
}

.app.invalid-schema,
.app.invalid-schema .details {
    background-color: lightsalmon;
}

.app {
    padding: 10px;
    font-size: 1rem;
    font-family: monospace;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
}

.app>div {
    margin: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details {
    overflow: auto;
    position: absolute;
    z-index: 100;
    background-color: inherit;
    max-height: 40vh;
    padding: 3px 30px;
    width: calc(100vw - 60px);
    /* top: 50px; */
    bottom: 0;
    left: 0;
    margin: 0;
    opacity: 1;
}
.details pre{
    margin:0;
    opacity: 0.5;
    user-select: none;
}
.details pre:hover{
    opacity: 1;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    /* content: attr(data-title); */
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    font-family: monospace;
    color: lightcyan;
    background-color: #333;
    /* color: #222; */
    white-space: nowrap;
    border-radius: 5px;
    box-shadow: 0px 0px 4px #222;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}